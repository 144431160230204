import React from 'react';
import PropTypes from 'prop-types';

import { Modal, Button, Alert } from 'antd';
import MarkdownWithHighlight from 'components/MarkdownWithHighlight';

import { bindActionCreators, compose } from 'redux';
import { connect } from 'react-redux';
import * as apiActions from 'redux/actions/api';
import { withRouter } from 'react-router';

class HerokuInvitation extends React.Component {
  static propTypes = {
    apiActions: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired
  };

  state = {
    modalVisibled: false,
    addons: []
  };

  UNSAFE_componentWillMount() {
    this._mounted = true;
  }

  componentWillUnmount() {
    this._mounted = false;
  }

  handleInvitationSend() {
    const { organizationId } = this.props.match.params;
    this.props.apiActions.post(
      {
        route:  `/dashboard/organizations/${organizationId}/herokuInvitations`,
        routeArgs: {}
      },
      (error, addons) => {
        if (error || !this._mounted) {
          return;
        }
        this.setState({ modalVisibled: true, addons });
      }
    );
  }

  renderModal() {
    return (
      <Modal
        key="modal"
        title="Free add-ons invitation"
        open={this.state.modalVisibled}
        onCancel={() => this.setState({ modalVisibled: false })}
        width={1000}
        footer={(
          <Button type="primary" onClick={() => this.setState({ modalVisibled: false })}>
            Close
          </Button>
        )}
      >
        <MarkdownWithHighlight
          content={`
> warning
> **Places are limited!**
> If you are not quick enough and all slots are filled, you won't be able to activate the add-ons!
> Don't wait to start them!

We have just sent you Heroku invitations for the new Stackhero add-ons.
As a thank you to our early adopters, we are **offering these add-ons free for life**!

Note that the Heroku CLI is required and the Heroku dashboard cannot be used.


### Here is how to start the free add-ons:

1. List your apps using your terminal and the Heroku CLI:
\`\`\`bash
heroku apps
\`\`\`


2. Set the app name that will be associated with the add-ons by replacing \`<APP_NAME>\` with one of your apps name:
\`\`\`bash
appname="<APP_NAME>"
\`\`\`


3. Start the add-ons by copy/paste these commands:
\`\`\`bash
${this.state.addons.map(addon => `heroku addons:create --app $appname ${addon}:test`).join('\n')}
\`\`\`


Congrats, you now have access to these add-ons for free, lifetime!

> We recommend to start all the add-ons, even if you don't plan to use them for now.
> This way you ensure to have them for free, lifetime and you'll be able to use them later if you change your mind.
>
> Also, do not delete them else you'll not be able to get them free again.
`}
    />
      </Modal>
    );
  }

  render() {
    return [
      this.renderModal(),
      (
        <Alert
          key="herokuAddOnsNew"
          message={(
            <>
              New add-ons are coming: OpenSearch (Elasticsearch fork), Valkey (Redis fork), Prometheus, Grafana and Matomo.
              <span style={{ fontWeight: 'bold' }}> We offer</span> them <span style={{ fontWeight: 'bold' }}>for free</span>, lifetime, to the <span style={{ fontWeight: 'bold' }}>first ones</span>!
              <Button type="link" onClick={this.handleInvitationSend.bind(this)}>I'm interested</Button>
            </>
          )}
          banner
          type={Math.random() < 0.5 ? 'info' : 'warning'}
        />
      )
    ];
  }
}

export default compose(
  withRouter,
  connect(
    () => ({}),
    dispatch => ({
      apiActions: bindActionCreators(apiActions, dispatch)
    })
  )
)(HerokuInvitation);