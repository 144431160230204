import React from 'react';
import PropTypes from 'prop-types';

import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as apiActions from 'redux/actions/api';
import * as appActions from 'redux/actions/app';
import * as navigationActions from 'redux/actions/navigation';

import { Alert, Modal, Button, Space, Row, Col, Badge } from 'antd';
import { AppstoreOutlined, IdcardOutlined, CalculatorOutlined, MessageOutlined, AlertOutlined, DashboardOutlined, FileTextOutlined } from '@ant-design/icons';

import Breadcrumb from 'components/Breadcrumb';
import countries from 'contents/countries';
import HerokuInvitation from 'components/HerokuInvitation';


import styles from './_container.css';

class _container extends React.Component {
  static propTypes = {
    api: PropTypes.object.isRequired,
    apiActions: PropTypes.object.isRequired,
    appActions: PropTypes.object.isRequired,
    app: PropTypes.object.isRequired,
    children: PropTypes.node,
    history: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
  };

  componentDidMount() {
    this._mounted = true;

    const { organizationId } = this.props.match.params;
    this._route = `/dashboard/organizations/${organizationId}`;
    this.props.apiActions.subscribe({
      route: this._route,
      routeArgs: {}
    });

    this.menuLeftSet();
  }

  componentDidUpdate() {
    this.menuLeftSet();
  }


  componentWillUnmount() {
    this._mounted = false;
    this.props.apiActions.unsubscribe({ route: this._route });
    this.props.appActions.menuLeftSet();
  }


  handleEmailValidationSend = () => {
    this.props.apiActions.post(
      {
        route: '/dashboard/emailValidationResend',
        routeArgs: {},
        handleErrorAutomatically: false
      },
      error => {
        if (!this._mounted) {
          return;
        }

        if (error === 'emailYetSended') {
          Modal.warning({
            title: 'You have to wait a minute...',
            content: '...before asking for a new validation email'
          });
          return;
        }
        else if (error) {
          throw error;
        }

        Modal.success({
          title: 'A new validation email has been sent'
        });
      }
    );
  };

  renderWarning() {
    if (this.props.app.sessionAuth.tokenOrigin === 'backoffice') {
      const message = 'Be careful!';
      const description = `You're connected as ${this.props.app.sessionAuth.email}`;
      return ( <Alert message={message} description={description} banner /> );
    }
  }

  renderAlerts() {
    const me = this.props.api.subscriptions['/dashboard/account'].datas;

    const alerts = [];

    // Development version
    if (process.env.NODE_ENV !== 'production') {
      alerts.push( <Alert key="development" message="Development version" banner /> );
    }

    // Email to validate
    if (!me.emailValidated && !/stacks\/stk-[a-z-0-9]{6}\/services\/create\//.test(this.props.location.pathname)) {
      alerts.push(
        <Alert
          key="email"
          message={(
            <Row>
              <Col xs={24} sm={24} md={24} flex={1}>
                You haven't validated your email {me.email}
              </Col>
              <Col style={{ textAlign: 'right' }}>
                <Space>
                  <Button size="small" onClick={this.handleEmailValidationSend}>Send validation email</Button>
                  <Button size="small" onClick={() => this.props.history.push('/account/')}>Change email</Button>
                </Space>
              </Col>
            </Row>
          )}
          banner
          type="info"
        />
      );
    }

    const organization = this.props.api.subscriptions[this._route].datas;
    // Unpaid invoices
    if (organization.hasUnpaidInvoices) {
      alerts.push(
        <Alert
          key="hasUnpaidInvoices"
          message={(
            <Row>
              <Col xs={24} sm={24} md={24} flex={1}>
                You have unpaid invoices. Update your payment method to avoid service disruption.
              </Col>
              <Col style={{ textAlign: 'right' }}>
                <Button size="small" onClick={() => this.props.history.push(`/account/organizations/${organization.id}/billing/paymentMethod/`)}>
                  Update payment method
                </Button>
              </Col>
            </Row>
          )}
          banner
          type="error"
        />
      );
    }
    else if (organization.isSuspended) {
      alerts.push(
        <Alert
          key="isSuspended"
          message="Your organization is suspended. Please contact our support to resolve this issue."
          banner
          type="error"
        />
      );
    }
    else if (organization.hasPaymentMethodAlmostExpired) {
      alerts.push(
        <Alert
          key="hasPaymentMethodAlmostExpired"
          message={(
            <Row>
              <Col xs={24} sm={24} md={24} flex={1}>
                Your card is about to expire. Update it to avoid any service disruption.
              </Col>
              <Col style={{ textAlign: 'right' }}>
                <Button size="small" onClick={() => this.props.history.push(`/account/organizations/${organization.id}/billing/paymentMethod/`)}>
                  Update payment method
                </Button>
              </Col>
            </Row>
          )}
          banner
          type="error"
        />
      );
    }
    else if (organization.hasPaymentMethodExpired) {
      alerts.push(
        <Alert
          key="hasPaymentMethodExpired"
          message={(
            <Row>
              <Col xs={24} sm={24} md={24} flex={1}>
                Your card is expired. Update it to avoid any service disruption.
              </Col>
              <Col style={{ textAlign: 'right' }}>
                <Button size="small" onClick={() => this.props.history.push(`/account/organizations/${organization.id}/billing/paymentMethod/`)}>
                  Update payment method
                </Button>
              </Col>
            </Row>
          )}
          banner
          type="error"
        />
      );
    }
    else if (!organization.hasPaymentMethod && organization.servicesCount) {
      alerts.push(
        <Alert
          key="paymentMethodToAdd"
          message={(
            <Row>
              <Col xs={24} sm={24} md={24} flex={1}>
                Demo account: services will be <span style={{ fontWeight: 'bold' }}>deleted</span> 24 hours after they have been started. Add a payment method to unlock your account and avoid deletion.
                <br />
                A payment method is only required for security purpose: we will not charge you while you have free coupons.
              </Col>
              <Col style={{ textAlign: 'right' }}>
                <Button size="small" onClick={() => this.props.history.push(`/account/organizations/${organization.id}/billing/paymentMethod/`)}>
                  Unlock your account and avoid service deletion
                </Button>
              </Col>
            </Row>
          )}
          banner
          type="error"
        />
      );
    }
    else {
      if (!/stacks\/stk-[a-z-0-9]{6}\/services\/create\//.test(this.props.location.pathname)) {
        const { coupons } = organization;
        const couponsNotUsed = coupons.filter(coupon => coupon.amountX100Consumed === 0);

        if (couponsNotUsed.length === 1) {
          const coupon = couponsNotUsed[0];
          const amountAvailableFormated = new Intl.NumberFormat(undefined, { style: 'currency', currency: coupon.currency, minimumFractionDigits: coupon.currencyHasCents ? 2 : 0 }).format(coupon.amountAvailable / 100);
          const availableUntilFormated = new Intl.DateTimeFormat(undefined).format(new Date(coupon.availableUntil));

          alerts.push(
            <Alert
              key="coupon"
              banner
              message={(
                <Row>
                  <Col xs={24} sm={24} md={24} flex={1}>
                    Don't forget that you have a {amountAvailableFormated} coupon to use before {availableUntilFormated}!
                  </Col>
                  <Col style={{ textAlign: 'right' }}>
                    <Button size="small" onClick={() => this.props.history.push(`/account/organizations/${organization.id}/billing/coupons/`)}>
                      See coupon details
                    </Button>
                  </Col>
                </Row>
              )}
              type="info"
            />
          );
        }
        else if (couponsNotUsed.length > 1) {
          alerts.push(
            <Alert
              key="coupon"
              banner
              message={(
                <Row>
                  <Col xs={24} sm={24} md={24} flex={1}>
                    You have coupons that you haven't used yet:
                    <br />
                    <ul>
                      {
                        couponsNotUsed
                          .map((coupon, index) => {
                            const amountAvailableFormated = new Intl.NumberFormat(undefined, { style: 'currency', currency: coupon.currency, minimumFractionDigits: coupon.currencyHasCents ? 2 : 0 }).format(coupon.amountAvailable / 100);
                            const availableUntilFormated = new Intl.DateTimeFormat(undefined).format(new Date(coupon.availableUntil));

                            return (
                              <li key={index}>
                                {amountAvailableFormated} left until {availableUntilFormated}, available for {coupon.serviceStoreTitleConcerned || 'all services'}.
                              </li>
                            );
                          })
                      }
                    </ul>
                  </Col>
                  <Col style={{ textAlign: 'right' }}>
                    <Button size="small" onClick={() => this.props.history.push(`/account/organizations/${organization.id}/billing/coupons/`)}>
                      See coupons details
                    </Button>
                  </Col>
                </Row>
              )}
              type="info"
            />
          );
        }

        if (!organization.vatNumber && countries.vatNumbersFormats[organization.countryCode]) {
          alerts.push(
            <Alert
              key="vatNumber"
              message={(
                <Row>
                  <Col xs={24} sm={24} md={24} flex={1}>
                    If you are an European company, you can avoid to pay VAT on your Stackhero's invoices!
                  </Col>
                  <Col style={{ textAlign: 'right' }}>
                    <Button size="small" onClick={() => this.props.history.push(`/account/organizations/${organization.id}/edit/`)}>
                      Add your VAT number
                    </Button>
                  </Col>
                </Row>
              )}
              banner
              type="info"
            />
          );
        }

        const me = this.props.api.subscriptions['/dashboard/account'].datas;
        if (organization.hasPaymentMethodType === 'heroku' && !/@herokumanager\.com/.test(me.email)) {
          alerts.push(<HerokuInvitation />);
        }
      }
    }

    return alerts;
  }


  menuLeftSet() {
    const subscription = this.props.api.subscriptions[this._route];
    const display = subscription && subscription.status !== 'initialize';

    if (!display) {
      return;
    }

    const organizationId = this.props.match.params.organizationId;
    const { userPermissions } = this.props.api.subscriptions[`/dashboard/organizations/${organizationId}`].datas;
    const stacksVisibled = userPermissions.indexOf('stacksAccess') !== -1;
    const organizationVisibled = userPermissions.indexOf('organizationConfigure') !== -1;
    const billingVisibled = userPermissions.indexOf('billing') !== -1;
    const prometheusVisibled = userPermissions.indexOf('prometheus') !== -1;
    const me = this.props.api.subscriptions['/dashboard/account'].datas;

    const baseUrl = `/account/organizations/${organizationId}`;
    this.props.appActions.menuLeftSet(
      [
        stacksVisibled
          ? {
            url: `${baseUrl}/stacks/`,
            label: (
              <>
                <AppstoreOutlined /> Stacks
              </>
            ),
          }
          : null,

        organizationVisibled
          ? {
            url: `${baseUrl}/`,
            label: (
              <>
                <IdcardOutlined /> Organization
              </>
            ),
            subItems: [
              {
                url: `${baseUrl}/edit/`,
                label: 'Edit'
              }
            ]
          }
          : null,

        billingVisibled
          ? {
            url: `${baseUrl}/billing/`,
            label: (
              <>
                <CalculatorOutlined /> Billing
              </>
            ),
            subItems: [
              {
                url: `${baseUrl}/billing/invoices/`,
                label: 'Invoices'
              },
              {
                url: `${baseUrl}/billing/coupons/`,
                label: 'Coupons'
              },
              {
                url: `${baseUrl}/billing/paymentMethod/`,
                label: 'Payment method'
              }
            ]
          }
          : null,

        // Prometheus
        prometheusVisibled
          ? {
            url: `${baseUrl}/prometheus/`,
            label: (
              <>
                <DashboardOutlined /> Prometheus
              </>
            ),
          }
          : null,

        // News
        {
          url: `${baseUrl}/news/`,
          label: (
            <>
              <FileTextOutlined /> News <Badge count={me.newsUnreadCount} offset={[ 20, 0 ]} />
            </>
          ),
        },

        // Status
        {
          url: 'https://twitter.com/StackheroStatus/',
          label: (
            <>
              <AlertOutlined /> Status
            </>
          ),
        },

        // Support
        {
          ...this.props.app.intercomAvailable
            ? { onClick: () => window.Intercom('showNewMessage') }
            : { mailto: 'mailto:support@stackhero.io' },
          label: (
            <>
              <MessageOutlined /> Support
            </>
          ),
        }
      ].filter(v => v)
    );
  }



  render() {
    const subscription = this.props.api.subscriptions[this._route];
    const display = subscription && subscription.status !== 'initialize';

    if (!display) {
      return null;
    }

    return (
      <>
        <div className={styles.contentSticked}>
          {this.renderWarning()}
          {
            this.renderAlerts()
              .map((alert, index) => (
                <div key={index} style={index > 0 ? { borderTop: '1px solid rgba(0, 0, 0, .1)' } : {}}>
                  {alert}
                </div>
              ))
          }
        </div>

        <div className={styles.contentCentered}>
          <Breadcrumb />
          {this.props.children}
        </div>
      </>
    );
  }
}

export default connect(
  ({ api, app }) => ({ api, app }),
  dispatch => ({
    navigationActions: bindActionCreators(navigationActions, dispatch),
    apiActions: bindActionCreators(apiActions, dispatch),
    appActions: bindActionCreators(appActions, dispatch)
  })
)(_container);
